import { styled, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "./EpisodeInfo.css";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
}));

const EpisodeInfo = (props) => {

  const episodes = props.episodes
  const current = props.current
  const onClick = props.onClick

  return (
    <>
      <Text className="episode-info-title">Episodes:</Text>
      <ul className="episode-info-list">
        {episodes.map((episode, index) => <li key={episode.id}><Text style={{ display: "inline" }} className="hand episode-info-episode" onClick={() => {if (onClick) onClick(index)}}>{index + 1}. {episode.ruName}</Text></li>)}
      </ul>
    </>
  );
};

export default EpisodeInfo;