import React from "react";
import { useSelector } from "react-redux";
import Header from "../../widgets/Header/index.js";
import LeftMenu from "../../widgets/LeftMenu/LeftMenu";
import "./styles.css";

const AppLayout = ({ children }) => {

  return (
    <>
      <div className="horizontal">
        <div id="left">
          <LeftMenu/>
        </div>
        <div id="right">
          <div><Header/></div>
          <div id='page-content'>{children}</div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
