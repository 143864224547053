import ACTION_SIGN_IN from "../actions/signIn";

function actionSignIn(user) {
  return {
    type: ACTION_SIGN_IN,
    user,
  };
}

export default actionSignIn;
