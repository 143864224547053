import React, { useRef, useState } from "react";
import "./CategorySelector.css";
import { Button, Chip, styled, TextField, Typography } from '@material-ui/core';

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const CategorySelector = (props) => {
  console.log(`${props.title}:`, props.items)

  const title = props.title
  const all = props.items
  const onItemsChanged = props.onItemChanged

  const newItemRef = useRef()
  const [items, setItems] = useState([])

  const setItemsWithNotification = (categories) => {
    const unique = Array.from(new Set(categories))
    setItems(unique)
    if (onItemsChanged) onItemsChanged(unique)
  }
  const withoutItem = (item) => items.filter((value) => value !== item)

  return (
    <>
      <Title>{title}</Title>
      <Title>Selected:</Title>
      <div>
        {items.map((item) => <Chip label={item.ruName || item.name} variant="outlined" onClick={() => {setItemsWithNotification(withoutItem(item))}} />)}
      </div>
      <br/>
      <Title>All:</Title>
      <div>
        {all.map((item) => <Chip label={item.ruName || item.name} variant="outlined" onClick={() => {setItemsWithNotification([item, ...items])}} />)}
      </div>
    </>
  );
};

export default CategorySelector;