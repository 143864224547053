import './NewVideoButton.css'
import { CircularProgress, Grid, Box, Paper, TextField, Card, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, MenuItem, Typography } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import GetRequest from '../../../net/GetRequest';
import ENDPOINTS from '../../../net/endpoints';
import { useMutation, useQuery } from 'react-query';
import REQUESTS from '../../../net/requests';
import { useSelector } from 'react-redux';
import ROLES from '../../../constants/roles';
import { useRef, useState } from 'react';
import Players from '../../../constants/players';


const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.secondary,
  }));

const NewVideoButton = (props) => {
    const releaseId = props.releaseId
    const [open, setOpen] = useState(false)
    const [player, setPlayer] = useState(Players.SIBNET)
    const [episode, setEpisode] = useState()
    const [team, setTeam] = useState()
    const urlRef = useRef()
    
    const episodesKey = `release.${releaseId}.episodes`
    const episodesRequest = useQuery(episodesKey, REQUESTS.getEpisodes(releaseId))
    const teamsKey = `teams`
    const teamsRequest = useQuery(teamsKey, REQUESTS.getVoiceTeams)

    const mutation = useMutation(REQUESTS.postVideo)

    const handleClose = () => { setOpen(false) }
    const handleCreate = () => { 
        const data = {
            player: Number(player),
            url: urlRef.current ? urlRef.current.value : "",
            releaseId: Number(releaseId),
            episodeId: Number(episode),
            voiceTeamId: Number(team),
            number: episodesRequest.data.filter(item => item.id === episode)[0].number
        }
        mutation.mutate(data, {
            onSuccess: (data, error, variables, context) => {
              handleClose()
            },
          })
    }

    if (episodesRequest.isLoading || teamsRequest.isLoading) {
        return <CircularProgress />
    }
    if (episodesRequest.isError || teamsRequest.isError) {
        return <p>Error</p>
    }


    return <>
            <Button onClick={ () => setOpen(true) }>New Video</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>New Video</DialogTitle>
                <DialogContent>
                    <Title>Player:</Title>
                    <Select value={player} onChange={event => setPlayer(event.target.value)} >
                        { Object.keys(Players).map(player => <MenuItem key={Object.keys(Players).indexOf(player)} value={Object.values(Players)[Object.keys(Players).indexOf(player)]}>{player}</MenuItem> ) }
                    </Select>
                    <TextField
                        inputRef={urlRef}
                        autoFocus
                        margin="dense"
                        id="url"
                        label="url"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <p>ReleaseId: {releaseId}</p>
                    <Title>Episode:</Title>
                    <Select value={episode} onChange={event => setEpisode(event.target.value)} >
                        { episodesRequest.data.map(episode => <MenuItem key={episode.id} value={episode.id}>{episode.number}</MenuItem> ) }
                    </Select>
                    <Title>Team:</Title>
                    <Select value={team} onChange={event => setTeam(event.target.value)} >
                        { teamsRequest.data.map(team => <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem> ) }
                    </Select>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
}

export default NewVideoButton;