const HOST = process.env.REACT_APP_ENV == "production" ? "https://ani-way.org:1441" : "http://localhost:3000";

const ENDPOINTS = {
  LOGIN: `${HOST}/v1/auth/login`,

  RELEASES: `${HOST}/v1/releases/`,
  RELEASES_POPULAR: `${HOST}/v1/releases/popular`,
  RELEASES_LATEST: `${HOST}/v1/releases/latest`,
  RELEASES_BY_GENRES: `${HOST}/v1/releases/byGenres`,
  TAGS: `${HOST}/v1/tags/`,
  RELEASE_TYPES: `${HOST}/v1/release-types/`,
  RELEASE_STATUSES: `${HOST}/v1/release-status/`,
  RELEASE_ANNOUNCEMENTS: `${HOST}/v1/announcements/`,
  CATEGORIES: `${HOST}/v1/categories/`,
  GENRES: `${HOST}/v1/genres/`,
  TEAMS: `${HOST}/v1/teams/`,
  STUDIOS: `${HOST}/v1/studios/`,
  repleasesPage: (pageNumber, pageSize) => `${HOST}/v1/releases/?offset=${(pageNumber) * pageSize}&pageSize=${pageSize}`,
  releasePage: (releaseId) => `${HOST}/v1/releases/${releaseId}`,
  releaseEpisodes: (releaseId) => `${HOST}/v1/releases/${releaseId}/episodes`,
  episodeVideos: (releaseId, episodeId) => `${HOST}/v1/releases/${releaseId}/episodes/${episodeId}/videos`,
  episodeVideosPack: (releaseId, episodeId) => `${HOST}/v1/releases/${releaseId}/episodes/${episodeId}/videos/forTeam`,
  search: (query) => `${HOST}/v1/search?${new URLSearchParams({q: query}).toString()}`,
  searchByType: (type, id) => `${HOST}/v1/releases/search?${new URLSearchParams({t: type, id}).toString()}`,
  releaseActivity: (id) => `${HOST}/v1/releases/${id}/activity`,
}

export default ENDPOINTS;