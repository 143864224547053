
const Activities = {
    0: 'Draft',
    1: 'Work in progress',
    2: 'Completed'
}

export const ServerActivities = {
    DRAFT: 0,
    WIP: 1,
    COMPLETED: 2
}

export default Activities