import { CircularProgress, styled, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import Players from "../../../constants/players";
import REQUESTS from "../../../net/requests";
import LiveSearchResult from "../LiveSearchResult";
import "./LiveSearch.css";

const REQUEST_DELAY = 1000

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

let timer
const LiveSearch = (props) => {

  const [query, setQuery] = useState("")
  const [showResult, setShowResult] = useState(false)
  
  const history = useHistory()

  const onItemClick = (item) => {
    setShowResult(false)
    setQuery("")
    if (history.location.pathname !== `/releases/${item.id}`) {
      history.push(`/releases/${item.id}`)
    }
  }

  const onChange = (event) => {
    setQuery(event.target.value)
    setShowResult(event.target.value.length > 2)
    if (event.target.value.length > 2) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        timer = null
        setShowResult(true)
      }, REQUEST_DELAY)
    }
  }

  return <div className="live-search">
    <TextField id="outlined-basic" className="search-input" label="Поиск" variant="outlined" size="small" value={query} onChange={onChange}/>
    { showResult && query.length > 2 && <LiveSearchResult query={query} onClick={onItemClick}/> }
    </div>;
};

export default LiveSearch;