import { Chip, CircularProgress, Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import REQUESTS from "../../../net/requests";
import "./SearchGenres.css";

const buildGenres = (request, genreMapper) => {
  if (request.isLoading) {
    return <CircularProgress />
  }
  if (request.isError) {
    return <p>Error</p>
  }
  return request.data.map(item => genreMapper(item));
}

const SearchGenres = (props) => {
  const title = props.title
  const request = props.request
  const requestKey = props.requestKey

  const genresRequest = useQuery(requestKey, request)
  const history = useHistory()

  const onItemClicked = (genre) => {
    const targetPath =  `/search/${requestKey}/${genre.id}`
    if (history.location.pathname !== targetPath) {
      history.push(targetPath)
    }
  }

  const view = buildGenres(genresRequest, (genre) => <Chip key={genre.id} label={genre.name || genre.ruName} variant="outlined" onClick={() => onItemClicked(genre)} style={{ marginLeft: 8}}/>)
  return (
    <div>
      <Paper className="search-genres-paper">
        <p>{ title }</p>
        { view }
      </Paper>
    </div>
  );
};

export default SearchGenres;