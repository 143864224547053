import { Button, CircularProgress,  Typography } from "@material-ui/core";
import { alpha, styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useEffect, useRef, useState } from "react";
import PlayerSelector from "../PlayerSelector";
import TeamSelector from "../TeamSelector";
import "./PlayerViewSelector.css";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  fontSize: '1rem'
}));
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#343434',
  },
  '&.Mui-active': {
    backgroundColor: '#343434',
  },
}));
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  borderColor: '#ffffff',
  border: 16,
  fontSize: '0.75rem',
  marginTop: 16,
  '& .MuiToggleButtonGroup-grouped': {
    color: '#ffffff',
    backgroundColor: '#1c1c1c',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#3e3e3e',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#343434',
    },
    '&.Mui-selected': {
      backgroundColor: '#343434',
      color: '#ffffff',

    }
  }
}));

const PlayerViewSelector = (props) => {
  const teamId = props.teamId
  const teams = props.teams
  const playerId = props.playerId
  const players = props.players
  const onChange = props.onChange

  const [showTeam, setShowTeam] = useState('left')
  const header = <StyledToggleButtonGroup
    value={showTeam}
    exclusive
    onChange={(e, newValue) => {if (newValue !== null) setShowTeam(newValue)}}
    aria-label="options"
  >
    <StyledToggleButton value="left" aria-label="left options">Озвучка</StyledToggleButton>
    <StyledToggleButton value="right" aria-label="right options">Плеер</StyledToggleButton>
  </StyledToggleButtonGroup>

  const emptyView = <div className="player-view-controls">
    {header}
  </div>

  if (!teamId || !playerId) {
    console.log('empty', teamId)
    return emptyView
  } else {
    console.log('not empty', teamId)
  }
  
  return <div>
        {header}
        {
          showTeam === 'left' ? <TeamSelector teams={teams} current={teamId} onChange={id => onChange(id, playerId)} /> : <PlayerSelector items={players} current={playerId} onChange={id => onChange(teamId, id)} />
        }
      </div>
};

export default PlayerViewSelector;