import React, { useEffect, useRef, useState } from "react";
import "./SibnetPlayerView.css";

const SibnetPlayerView = (props) => {

  const url = props.url

  return (
    <>
      <iframe width="987" height="555" src={url} frameBorder="0" scrolling="no" allowFullScreen={true} />
    </>
  );
};

export default SibnetPlayerView;