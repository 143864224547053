import { Box, CircularProgress, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, styled, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import REQUESTS from "../../../net/requests";
import "./LiveSearchResult.css";

const LiveSearchResult = (props) => {

  const query = props.query
  const onClick = props.onClick

  const searchKey = `search.${query}`
  const searchRequest = useQuery(searchKey, REQUESTS.search(query))

  if (searchRequest.isLoading) {
    return <div className="live-search-result">
      <CircularProgress className="live-search-result-loading"/>
    </div>
  }
  if (searchRequest.isError) {
    return <p>Error2</p>
  }

  return <ul className="live-search-result">
    {searchRequest.data.map(result => <li key={result.id} className="live-search-result-item" onClick={() => onClick ? onClick(result) : undefined}>{result.titleRu}</li>)}
  </ul>;
};

export default LiveSearchResult;