import './ReleaseEpisodes.css'
import { CircularProgress, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import REQUESTS from '../../../net/requests';
import { useSelector } from 'react-redux';
import ROLES from '../../../constants/roles';
import { useRef, useState } from 'react';
import NewVideoButton from '../NewVideoButton';
import NewVideoPackButton from '../NewVideoPackButton';

const EpisodeButton = styled(Button)(({ theme }) => ({
    width: 48,
    minWidth: 48,
    height: 48,
    minHeight: 48,
    marginRight: 16,
    marginBottom: 8
}));

const ReleaseEpisodes = (props) => {
    const releaseId = props.releaseId
    const currentItem = props.currentItem
    const onItemSelected = props.onItemSelected
    const user = useSelector(state => state.user)
    const [open, setOpen] = useState(false);
    const nameRef = useRef()
    const numberRef = useRef()
    const urlRef = useRef()
    const queryClient = useQueryClient()
    const mutation = useMutation(REQUESTS.postEpisode)

    const episodesKey = `release.${releaseId}.episodes`
    const episodesRequest = useQuery(episodesKey, REQUESTS.getEpisodes(releaseId))

    const handleClose = () => { setOpen(false) }
    const handleCreate = () => { 
        const data = {
            id: releaseId,
            ruName: nameRef.current ? nameRef.current.value : '',
            enName: '',
            previewUrl: urlRef.current ? urlRef.current.value : '',
            number: numberRef.current ? Number(numberRef.current.value) : 0,
        }
        mutation.mutate(data, {
            onSuccess: (data, error, variables, context) => {
                queryClient.invalidateQueries(episodesKey, { exact: true })
                handleClose()
            },
          })
    }
    const openEpisode = (episode) => { 
        if (onItemSelected) onItemSelected(episode)
     }

    if (episodesRequest.isLoading) {
        return <CircularProgress />
    }
    if (episodesRequest.isError) {
        return <p>Error</p>
    }

    const numbers = episodesRequest.data.map(item => <EpisodeButton key={item.number} variant={item.number === currentItem ? "contained" : "outlined"} onClick={() => openEpisode(item)}>{item.number}</EpisodeButton>)
    if (user && (user.role === ROLES.ADMIN || user.role === ROLES.MODERATOR)) {
        numbers.push(<Button key={0} variant="outlined" onClick={() => setOpen(true)} sx={{sw: 48}}>new episode</Button>)
        numbers.push(<NewVideoButton key={-1} releaseId={releaseId} />)
        numbers.push(<NewVideoPackButton key={-2} releaseId={releaseId} />)
    }

    return <div className='release-episodes'>
            {numbers}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>New Episode</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter values
                    </DialogContentText>
                    <TextField
                        inputRef={nameRef}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        inputRef={numberRef}
                        autoFocus
                        margin="dense"
                        id="number"
                        label="Number"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        inputRef={urlRef}
                        autoFocus
                        margin="dense"
                        id="preview"
                        label="preview"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
}

export default ReleaseEpisodes;