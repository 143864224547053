import { CircularProgress, styled, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Players from "../../../constants/players";
import "./PopularReleases.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useQuery } from "react-query";
import REQUESTS from "../../../net/requests";
import ReleasePreview from "../ReleasePreview";
import { useHistory } from "react-router";
import { Pages } from "../../../constants/urls";
import MainPageList from "../MainPageList";


const PopularReleases = (props) => {
  //may be replease with Expanding Collection + Carousel Slider

  const requestKey = props.requestKey;
  const requestItem = props.requestItem;
  const slidesPerView = props.slidesPerView;

  const itemWidth = 312;
  const RESET_TIMEOUT = 150;

  const history = useHistory();
  //const targetRef = useRef();
  //const [dimensions, setDimensions] = useState({ width: itemWidth, height: 0 });

  const request = useQuery(requestKey, requestItem)

  const timer = useRef(null);

  // const testDimensions = () => {
  //   if (targetRef.current) {
  //     setDimensions({
  //       width: targetRef.current.offsetWidth,
  //       height: targetRef.current.offsetHeight
  //     });
  //   }
  // }

  // useLayoutEffect(() => {
  //   testDimensions()
  // }, []);

  if (request.isLoading) {
      return <div id="center-content">
          <CircularProgress />
      </div>
  }
  if (request.isError) {
      return <div id="center-content">
          <p>Error!</p>
      </div>
  }

  // window.addEventListener('resize', () => {
  //   if (timer.current) {
  //     clearTimeout(timer.current);
  //   }
  //   timer.current = setTimeout(testDimensions, RESET_TIMEOUT);
  // });

  return (
    <div>
      <MainPageList key={requestKey} items={request.data} slidesPerView={slidesPerView < 1 ? 1 : slidesPerView}/>
    </div>
  );
};

export default PopularReleases;