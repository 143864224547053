import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import Routers from "./Routers";
import store from "./redux/store";

import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./styles/index.css";
import mainTheme from "./styles/mainTheme";

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <Provider store={store}>
      <Router>
        <Routers />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

