import React from "react";
import "./LeftMenu.css";
import URLS from "../../../constants/urls";
import { Link } from "react-router-dom";

const LeftMenu = ({ children }) => {

  return (
    <>
      <div id="logo">Ani-Way Project</div>
      <ul>
        <li><Link to={URLS.ROOT_URL}>Главная</Link></li>
        <li><Link to={URLS.RELEASES}>Релизы</Link></li>
        <li><Link to={URLS.SEARCH}>Поиск</Link></li>
      </ul>
    </>
  );
};

export default LeftMenu;