import React, { useRef } from "react";
import "./NewReleaseType.css";
import { Button, styled, TextField, Typography } from '@material-ui/core';
import REQUESTS from "../../../net/requests";
import { useMutation } from "react-query";

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const NewReleaseType = (props) => {

  const hint = props.hint
  const buttonText = props.buttonText
  const request = props.request
  const onNameAdded = props.onNameAdded

  const newItemRef = useRef()

  const mutation = useMutation(request)

  const addItem = () => {
    const name = newItemRef.current.value
    if (name) {
      mutation.mutate(name, {
        onSuccess: (data, variables, context) => {
          if (onNameAdded) {
            onNameAdded(data)
          }
        },
        onError: () => {
          console.log('onError!')
          setTimeout(() => {
            mutation.reset()
          }, 5000)
        }
      })
    }
    newItemRef.current.value = ""
  }

  return (
    mutation.isLoading ? <Title>Loading</Title> :
      mutation.isError ? <Title>Error: {JSON.stringify(mutation.error.response.data)}</Title> :
        <>
          <TextField inputRef={newItemRef} id="newItem" label={hint} variant="outlined" size="small" />
          <Button variant="outlined" onClick={addItem} style={{ width: 200, marginLeft: 16 }}>
            {buttonText}
          </Button>
        </>
  );
};

export default NewReleaseType;