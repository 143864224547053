import { Button, CircularProgress, styled, Typography } from "@material-ui/core";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import Players from "../../../constants/players";
import REQUESTS from "../../../net/requests";
import AniboomPlayerView from "../players/AniboomPlayerView";
import KodikPlayerView from "../players/KodikPlayerView";
import VkVideoPlayerView from "../players/VkVideoPlayerView";
import VstreamPlayerView from "../players/VstreamPlayerView";
import YugenPlayerView from "../players/YugenPlayerView";
import PlayerSelector from "../PlayerSelector";
import PlayerViewSelector from "../PlayerViewSelector";
import SibnetPlayerView from "../players/SibnetPlayerView";
import TeamSelector from "../TeamSelector";
import "./PlayerView.css";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  fontSize: '1rem'
}));

const PlayerView = (props) => {
  const releaseId = props.releaseId
  const episodeId = props.episodeId

  const [currentTeamId, selectTeamId] = useState()
  const [currentPlayerId, selectPlayerId] = useState()

  const videosKey = `release.${releaseId}.episodes.${episodeId}.videos`
  const videosRequest = useQuery(videosKey, REQUESTS.getVideos(releaseId, episodeId))

  const loadingView = <div className="player-view-container">
      <div className="player-view-frame">
        <CircularProgress />
      </div>
      <PlayerViewSelector />
    </div>
  const textView = (text) => <div className="player-view-container">
      <div className="player-view-frame">
        <Text>{text}</Text>
      </div>
      <PlayerViewSelector />
    </div>

  if (videosRequest.isLoading) {
    return loadingView
  }
  if (videosRequest.isError) {
    return textView('Error')
  }
  const currentVideos = videosRequest.data.filter(video => video.episodeId === episodeId)

  if (currentVideos.length === 0) {
    return textView('Empty')
  }

  if (!currentTeamId) selectTeamId(currentVideos[0].voiceTeamId)
  if (!currentPlayerId) selectPlayerId(currentVideos[0].player)

  if (!currentTeamId || !currentPlayerId) {
    return loadingView
  }

  const currentTeamVideos = currentVideos.filter(video => video.voiceTeamId === currentTeamId)

  if (currentTeamVideos.length === 0) {
    selectTeamId(currentVideos[0].voiceTeamId)
    return loadingView
  }

  const playerVideos = currentTeamVideos.filter(video => video.player === currentPlayerId)
  const playerVideo = playerVideos.length > 0 ? playerVideos[0] : undefined
  if (!playerVideo) {
    selectPlayerId(currentTeamVideos[0].player)
    return loadingView
  }

  const videoUrl = playerVideo.url
  let playerView
  switch (currentPlayerId) {
    case Players.KODIK:
      playerView = <KodikPlayerView url={videoUrl} />
      break;
    case Players.SIBNET:
      playerView = <SibnetPlayerView url={videoUrl} />
      break;
    case Players.ANIBOOM:
      playerView = <AniboomPlayerView url={videoUrl} />
      break;
    case Players.YUGEN:
      playerView = <YugenPlayerView url={videoUrl} />
      break;
    case Players.VSTREAM:
      playerView = <VstreamPlayerView url={videoUrl} />
      break;
    case Players.VK_VIDEO:
      playerView = <VkVideoPlayerView url={videoUrl} />
      break;
  }

  const updatePlayerContent = (teamId, playerId) => {
    if (currentTeamId !== teamId) {
      selectTeamId(teamId)
    }
    if (currentPlayerId !== playerId) {
      selectPlayerId(playerId)
    }
  }
  const teams = Array.from(new Set(currentVideos.map(v => v.voiceTeamId)))
  const players = currentTeamVideos.map(v => v.player)
  
  return <div className="player-view-container">
      <div className="player-view-frame">
        { playerView }
      </div>
      <div className="player-view-controls">
        <PlayerViewSelector teamId={currentTeamId} teams={teams} playerId={currentPlayerId} players={players} onChange={updatePlayerContent} />
      </div>
    </div>
};

export default PlayerView;