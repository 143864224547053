import React from "react";
import GoogleLogin from "react-google-login";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  google: {
    width: "100%",
    height: "45px",
    marginTop: "16px",
    fontSize: "16px",
  },
  logoGoogle: {
    width: "20px",
    height: "20px",
  },
};

const useStyles = makeStyles((theme) => ({
  google: {
    background: "#ffffff",
    textTransform: "none",
    color: "#6C757D",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&$focusVisible": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
    "&$disabled": {
      boxShadow: "none",
    },
  },
}));

const GoogleButton = ({ text, onSuccess, onFailure }) => {
  const classes = useStyles();

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={(error) => onFailure(error)}
      render={(renderProps) => (
        <Button
          className={classes.google}
          style={styles.google}
          variant="outlined"
          startIcon={
            <img src="/svg/ic_google.svg" style={styles.logoGoogle} alt="" />
          }
          disabled={renderProps.disabled}
          onClick={renderProps.onClick}
        >
          {text}
        </Button>
      )}
    />
  );
};

export default GoogleButton;
