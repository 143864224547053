import ENDPOINTS from "./endpoints";
import restClient from "./RestService";

const REQUESTS = {
    getTags: () => restClient.get(ENDPOINTS.TAGS).then((res) => res.data),
    getReleaseTypes: () => restClient.get(ENDPOINTS.RELEASE_TYPES).then((res) => res.data),
    getReleaseStatuses: () => restClient.get(ENDPOINTS.RELEASE_STATUSES).then((res) => res.data),
    getReleaseAccouncements: () => restClient.get(ENDPOINTS.RELEASE_ANNOUNCEMENTS).then((res) => res.data),
    getCategories: () => restClient.get(ENDPOINTS.CATEGORIES).then((res) => res.data),
    getGenres: () => restClient.get(ENDPOINTS.GENRES).then((res) => res.data),
    getReleases: () => restClient.get(ENDPOINTS.RELEASES).then((res) => res.data.releases),
    getPopularReleases: () => restClient.get(ENDPOINTS.RELEASES_POPULAR).then((res) => res.data.releases),
    getLatestReleases: () => restClient.get(ENDPOINTS.RELEASES_LATEST).then((res) => res.data.releases),
    getByGenres: (ids) => () => restClient.post(ENDPOINTS.RELEASES_BY_GENRES, { genreIds: ids }).then((res) => res.data.releases),
    getReleasesForSearch: (type, id) => () => restClient.get(ENDPOINTS.searchByType(type, id)).then((res) => res.data.releases),
    getRelease: (releaseId) => { return () => restClient.get(ENDPOINTS.releasePage(releaseId)).then((res) => res.data)},
    getEpisodes: (releaseId) => { return () => restClient.get(ENDPOINTS.releaseEpisodes(releaseId)).then((res) => res.data)},
    getVoiceTeams: () => restClient.get(ENDPOINTS.TEAMS).then((res) => res.data),
    getVideos: (releaseId, episodeId) => ( () => restClient.get(ENDPOINTS.episodeVideos(releaseId, episodeId)).then((res) => res.data) ),
    getStudios: () => restClient.get(ENDPOINTS.STUDIOS).then((res) => res.data),

    postReleaseType: (name) => restClient.post(ENDPOINTS.RELEASE_TYPES, { name }).then((res) => res.data),
    postReleaseStatus: (name) => restClient.post(ENDPOINTS.RELEASE_STATUSES, { name }).then((res) => res.data),
    postReleaseAccouncement: (name) => restClient.post(ENDPOINTS.RELEASE_ANNOUNCEMENTS, { name }).then((res) => res.data),
    postCategories: (name) => restClient.post(ENDPOINTS.CATEGORIES, {
        ruName: name,
        enName: name,
        key: ""
    }).then((res) => res.data),
    postGenres: (name) => restClient.post(ENDPOINTS.GENRES, {
        name: name,
        key: ""
    }).then((res) => res.data),
    postTags: (name) => restClient.post(ENDPOINTS.TAGS, { name }).then((res) => res.data),
    postRelease: (release) => restClient.post(ENDPOINTS.RELEASES, release).then((res) => res.data),
    postEpisode: (release) => restClient.post(ENDPOINTS.releaseEpisodes(release.id), release).then((res) => res.data),
    postVideo: (video) => restClient.post(ENDPOINTS.episodeVideos(video.releaseId, video.episodeId), video).then((res) => res.data),
    postVideoPack: (pack) => restClient.post(ENDPOINTS.episodeVideosPack(pack.releaseId, 0), pack).then((res) => res.data),
    postStudio: (name) => restClient.post(ENDPOINTS.STUDIOS, { name }).then((res) => res.data),

    search: (query) => () => restClient.get(ENDPOINTS.search(query)).then((res) => res.data),
    postReleaseActivity: (releaseId) => (activity) => restClient.post(ENDPOINTS.releaseActivity(releaseId), { activity }).then((res) => res.data),
}
  
export default REQUESTS;