import React, { useRef, useState } from "react";
import "./ChipsSelector.css";
import { Button, Chip, styled, TextField, Typography } from '@material-ui/core';

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const ChipsSelector = (props) => {

  const title = props.title
  const hint = props.hint
  const buttonText = props.buttonText
  const onItemsChanged = props.onItemChanged

  const newItemRef = useRef()
  const [items, setItems] = useState([])
  const [newItem, setNewItem] = useState("")

  const setItemsWithNotification = (items) => {
    setItems(items)
    if (onItemsChanged) onItemsChanged(items)
  }
  const withoutItem = (item) => items.filter((value) => value !== item)
  const addTag = () => {
    if (newItemRef.current.value.length > 0) {
      setItemsWithNotification([newItemRef.current.value, ...items])
      onItemsChanged()
    }
    newItemRef.current.value = ""
  }

  return (
    <>
      <Title>{title}</Title>
      <div>
        {items.map((item) => <Chip label={item} variant="outlined" onClick={() => {setItemsWithNotification(withoutItem(item))}} />)}
      </div>
      <br/>
      <TextField inputRef={newItemRef} id="newItem" label={hint} variant="outlined" size="small" />
      <Button variant="outlined" onClick={addTag} style={{ width: 200, marginLeft: 16 }}>
        {buttonText}
      </Button>
    </>
  );
};

export default ChipsSelector;