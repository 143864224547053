import React from "react";

import AppLayout from "../../components/layouts/AppLayout";
import PagedList from "../../components/widgets/PagedList";
import SearchGenres from "../../components/widgets/SearchGenres";
import SearchList from "../../components/widgets/SearchList";
import SearchTypes from "../../constants/search-types";
import REQUESTS from "../../net/requests";
import "./SearchPage.css"

const SearchPage = (props) => {

  const type = props.match.params.type
  const id = props.match.params.id

  if (Object.values(SearchTypes).indexOf(type) === -1 || !id) {
    return (
      <div className="search-page">
        <SearchGenres title="Genres" request={REQUESTS.getGenres} requestKey={SearchTypes.GENRES} />
        <SearchGenres title="Categories" request={REQUESTS.getCategories} requestKey={SearchTypes.CATEGORIES} />
        <SearchGenres title="Tags" request={REQUESTS.getTags} requestKey={SearchTypes.TAGS} />
        <PagedList />
      </div>
    );
  }

  return (
    <div className="search-page">
      <SearchGenres title="Genres" request={REQUESTS.getGenres} requestKey={SearchTypes.GENRES} />
      <SearchGenres title="Categories" request={REQUESTS.getCategories} requestKey={SearchTypes.CATEGORIES} />
      <SearchGenres title="Tags" request={REQUESTS.getTags} requestKey={SearchTypes.TAGS} />
      <SearchList {...props} />
    </div>
  );
};

export default SearchPage;
