import { createTheme } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";

export default createTheme({
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  palette: {
    type: 'dark',
    primary: {
      main: "#8855bc",
    },
    secondary: {
      main: "#78c0e0",
    },
    success: {
      main: "#28A745",
    },
    error: {
      main: "#DC3545",
    },
    warning: {
      main: "#FFC107",
    },
    info: {
      main: "#F8F9FA",
    },
    text: {
      muted: "#6C757D",
      onSurface: "#000000",
      onBackground: "#ffffff"
    },
    background: {
      default: '#1c1c1c',
      paper: '#242426',
      white: '#ffffff'
    },
  },
  props: {
    MuiPopover: {
      TransitionComponent: Fade,
    },
  },
});
