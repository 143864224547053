
const Players = {
    NONE: 0,
    KODIK: 1,
    SIBNET: 2,
    ANIBOOM: 3,
    YUGEN: 4,
    VSTREAM: 5,
    VK_VIDEO: 6

}

export default Players