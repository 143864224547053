import React, { useRef, useState } from "react";

import { Button, CircularProgress, MenuItem, Select, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import "./NewReleasePage.css";
import { useHistory, useLocation } from "react-router";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChipsSelector from "../../components/widgets/ChipsSelector";
import CategorySelector from "../../components/widgets/CategorySelector";
import NewCategory from "../../components/widgets/NewCategory";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import REQUESTS from "../../net/requests";
import AgeRating from "../../constants/age-raiting";
import ReleaseTypeSelector from "../../components/widgets/ReleaseTypeSelector";
import NewReleaseType from "../../components/widgets/NewReleaseType";
import restClient from "../../net/RestService";
import AltNamesSelector from "../../components/widgets/AltNamesSelector";
import dayjs from "dayjs";
import QUERY_KEYS from "../../net/query-keys";

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const DatePickerLight = styled(DatePicker)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.white,
  textAlign: 'start',
  color: 'white',
  border: '2px solid white',
  borderRadius: 4,
}));

const NewReleasePage = (props) => {
  console.log(restClient.token)
  const location = useLocation();
  const queryClient = useQueryClient()
  const [ageValue, setAgeValue] = useState(AgeRating.G)
  const [altNames, setAltNames] = useState([])
  const [releasedDate, setReleasedDate] = useState(dayjs())
  const [releaseType, setReleaseType] = useState()
  const [releaseStatus, setReleaseStatus] = useState()
  const [releaseAccouncement, setReleaseAccouncement] = useState()
  const [releaseSeasonDate, setReleaseSeasonDate] = useState(dayjs())
  const [releaseStart, setReleaseStart] = useState(dayjs())
  const [releaseEnd, setReleaseEnd] = useState(dayjs())
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [studios, setStudios] = useState([])
  const [genres, setGenres] = useState([])
  const history = useHistory()
  
  const handleChange = () => {}

  const buildView = (request, view) => {
    if (request.isLoading) {
      return <CircularProgress />
    }
    if (request.isError) {
      return <Title>Error: {JSON.stringify(request.error.response.data)}</Title>
    }
    return view
  }

  const releaseTypesRequest = useQuery(QUERY_KEYS.RELEASE_TYPES, REQUESTS.getReleaseTypes)
  const releaseTypesView = buildView(releaseTypesRequest, <ReleaseTypeSelector title="Release Type" items={releaseTypesRequest.data} onItemSelected={item => setReleaseType(item)}/>)

  const releaseStatusRequest = useQuery(QUERY_KEYS.RELEASE_STATUSES, REQUESTS.getReleaseStatuses)
  const releaseStatusView = buildView(releaseStatusRequest, <ReleaseTypeSelector title="Release Status" items={releaseStatusRequest.data} onItemSelected={item => setReleaseStatus(item)}/>)

  const releaseAccouncementsKey = 'releaseAccouncements'
  const releaseAccouncementsRequest = useQuery(releaseAccouncementsKey, REQUESTS.getReleaseAccouncements)
  const releaseAccouncementsView = buildView(releaseAccouncementsRequest, <ReleaseTypeSelector title="Accouncement" items={releaseAccouncementsRequest.data} onItemSelected={item => setReleaseAccouncement(item)}/>)


  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)
  const categoriesDataView = <>
    <CategorySelector title="Categories" items={categoriesRequest.data} onItemChanged={(items) => setCategories(items)}/>
    <br/>
    <NewCategory hint="New Category" buttonText="Add new Category" request={REQUESTS.postCategories} onNameAdded={(name) => { queryClient.invalidateQueries(QUERY_KEYS.CATEGORIES, { exact: true }) }}/>
  </>
  const categoriesView = buildView(categoriesRequest, categoriesDataView)

  const genresRequest = useQuery(QUERY_KEYS.GENRES, REQUESTS.getGenres)
  const genresDataView = <>
    <CategorySelector title="Genres" items={genresRequest.data} onItemChanged={(items) => setGenres(items)}/>
    <br/>
    <NewCategory hint="New Genre" buttonText="Add new Genre" request={REQUESTS.postGenres} onNameAdded={(name) => { queryClient.invalidateQueries(QUERY_KEYS.GENRES, { exact: true }) }}/>
  </>
  const genresView = buildView(genresRequest, genresDataView)

  const tagsRequest = useQuery(QUERY_KEYS.TAGS, REQUESTS.getTags)
  const tagsDataView = <>
    <CategorySelector title="Tags" items={tagsRequest.data} onItemChanged={(items) => setTags(items)}/>
    <br/>
    <NewCategory hint="New Tag" buttonText="Add new Tag" request={REQUESTS.postTags} onNameAdded={(name) => { queryClient.invalidateQueries(QUERY_KEYS.TAGS, { exact: true }) }}/>
  </>
  const tagsView = buildView(tagsRequest, tagsDataView)

  const studiosRequest = useQuery(QUERY_KEYS.STUDIOS, REQUESTS.getStudios)
  const studiosDataView = <>
    <CategorySelector title="Studios" items={studiosRequest.data} onItemChanged={(items) => setStudios(items && items.length > 0 ? [items[0]] : [])}/>
    <br/>
    <NewCategory hint="New Studio" buttonText="Add new Studio" request={REQUESTS.postStudio} onNameAdded={(name) => { queryClient.invalidateQueries(QUERY_KEYS.STUDIOS, { exact: true }) }}/>
  </>
  const studiosView = buildView(studiosRequest, studiosDataView)

  const mutation = useMutation(REQUESTS.postRelease)

  const ruNameRef = useRef()
  const enNameRef = useRef()
  const ruDescRef = useRef()
  const enDescRef = useRef()
  const seasonNumberRef = useRef()
  const episodesAiredRef = useRef()
  const episodesCountRef = useRef()
  const episodeDurationRef = useRef()
  const ratingRef = useRef()
  const coverUrlRef = useRef()

  const buildRelease = () => {
    const request = {
      titleRu: ruNameRef.current ? ruNameRef.current.value : "",
      titleEn: enNameRef.current ? enNameRef.current.value : "",
      descriptionRu: ruDescRef.current ? ruDescRef.current.value : "",
      descriptionEn: enDescRef.current ? enDescRef.current.value : "",
      altNames,
      releasedDate: releasedDate ? releasedDate.valueOf() : 0,
      releaseTypeId: releaseType ? releaseType : releaseTypesRequest.data[0].id,
      statusId: releaseStatus ? releaseStatus : releaseStatusRequest.data[0].id,
      announcementId: releaseAccouncement ? releaseAccouncement : releaseAccouncementsRequest.data[0].id,
      releaseSeasonDate: releaseSeasonDate ? releaseSeasonDate.valueOf() : 0,
      seasonNumber: seasonNumberRef.current ? Number(seasonNumberRef.current.value) : undefined,
      episodesAired: episodesAiredRef.current ? Number(episodesAiredRef.current.value) : undefined,
      episodesCount: episodesCountRef.current ? Number(episodesCountRef.current.value) : undefined,
      episodeDuration: episodeDurationRef.current ? Number(episodeDurationRef.current.value) : undefined,
      rating: ratingRef.current ? Number(ratingRef.current.value) : undefined,
      ageRating: Object.keys(AgeRating).indexOf(ageValue),
      releaseStart: releaseStart ? releaseStart.valueOf() : 0,
      releaseEnd : releaseEnd ? releaseEnd.valueOf() : 0,
      coverUrl: coverUrlRef.current ? coverUrlRef.current.value : "",
      categoryIds: categories.map(item => item.id),
      tagIds: tags.map(tag => tag.id),
      studioId: studios && studios.length > 0 ? studios[0].id : null,
      genreIds: genres.map(genre => genre.id),
    }
    return request
  }

  return (
    <div className="new-release-form">
        <Title>New Release</Title>
        <br/>
        <TextField id="titleRu" label="Title Ru" variant="outlined" size="small" inputRef={ruNameRef} />
        <br/>
        <TextField id="titleEn" label="Title En" variant="outlined" size="small" inputRef={enNameRef} />
        <br/>
        <br/>
        <Title>Description Ru</Title>
        <TextareaAutosize
          id="descriptionRu"
          ref={ruDescRef}
          aria-label="minimum height"
          minRows={3}
          placeholder="Ru"
          style={{ width: 600 }}
        />
        <br/>
        
        <Title>Description En</Title>
        <TextareaAutosize
          id="descriptionEn"
          ref={enDescRef}
          aria-label="minimum height"
          minRows={3}
          placeholder="En"
          style={{ width: 600 }}
        />
        <br/>
        <AltNamesSelector onItemsChanged={items => setAltNames(items)}/>

        <Title>Released Date</Title>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerLight value={releasedDate} onChange={(date) => setReleasedDate(date)} />
        </LocalizationProvider>
        
        <br/>
        <br/>
        <Title>Age Rating</Title>
        <Select
          labelId="age-rating-label"
          id="ageRating"
          value={ageValue}
          label="Age Rating"
          onChange={event => setAgeValue(event.target.value)}
        >
          {
            Object.keys(AgeRating).map(key => {
              return <MenuItem value={key}>{Object.values(AgeRating)[Object.keys(AgeRating).indexOf(key)]}</MenuItem> 
            })
          }
        </Select>
        <br/>
        <br/>
        <TextField id="score" className="search-input" label="score" variant="outlined" size="small" />
        <br/>
        <br/>
        {releaseTypesView}
        <br/>
        <NewReleaseType hint="New release type" buttonText="Create" request={REQUESTS.postReleaseType} onNameAdded={() => queryClient.invalidateQueries(QUERY_KEYS.RELEASE_TYPES, { exact: true })}/>
        <br/>
        <br/>
        {releaseStatusView}
        <br/>
        <NewReleaseType hint="New release status" buttonText="Create" request={REQUESTS.postReleaseStatus} onNameAdded={() => queryClient.invalidateQueries(QUERY_KEYS.RELEASE_STATUSES, { exact: true })}/>
        <br/>
        <br/>
        {releaseAccouncementsView}
        <br/>
        <NewReleaseType hint="New release Accouncement" buttonText="Create" request={REQUESTS.postReleaseAccouncement} onNameAdded={() => queryClient.invalidateQueries(releaseAccouncementsKey, { exact: true })}/>
        <br/>
        <br/>
        <Title>Release Season Date</Title>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerLight value={releaseSeasonDate} onChange={date => setReleaseSeasonDate(date)}/>
        </LocalizationProvider>
        <br/>
        <br/>
        <TextField id="seasonNumber" className="search-input" label="seasonNumber" variant="outlined" size="small" inputRef={seasonNumberRef} />
        <br/>
        <TextField id="episodesAired" className="search-input" label="episodesAired" variant="outlined" size="small" inputRef={episodesAiredRef} />
        <br/>
        <TextField id="episodesCount" className="search-input" label="episodesCount" variant="outlined" size="small" inputRef={episodesCountRef} />
        <br/>
        <TextField id="episodeDuration" className="search-input" label="episodeDuration" variant="outlined" size="small" inputRef={episodeDurationRef} />
        <br/>
        <TextField id="rating" className="search-input" label="rating" variant="outlined" size="small" inputRef={ratingRef} />
        <br/>
        <Title>Release Start</Title>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerLight value={releaseStart} onChange={(date) => setReleaseStart(date)} />
        </LocalizationProvider>
        <br/>
        <br/>
        <Title>Release End</Title>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerLight value={releaseEnd} onChange={(date) => setReleaseEnd(date)} />
        </LocalizationProvider>
        <br/>
        <br/>
        <TextField id="coverUrl" className="search-input" label="coverUrl" variant="outlined" size="small" inputRef={coverUrlRef}/>
        <br/>
        <br/>
        <br/>
        <br/>

        {categoriesView}
        <br/>
        <br/>
        {genresView}
        <br/>
        {tagsView}
        <br/>
        <br/>
        {studiosView}
        <br/>
        <br/>

        {
        mutation.isLoading ? <Title>Loading</Title> :
          mutation.isError ? <Title>Error: {JSON.stringify(mutation.error.response.data)}</Title> :
          <div>
            <Button variant="outlined" onClick={() => {
              const release = buildRelease()
              mutation.mutate(release, {
                onSuccess: (data, error, variables, context) => {
                  console.log('created!')
                  history.push(`/releases/${data.id}`)
                },
                onError: () => {
                  console.log('onError!')
                  setTimeout(() => {
                    mutation.reset()
                  }, 3000)
                }
              })
              console.log("json:", release)
            }} style={{ width: 600 }}>
              Create release
            </Button>
          </div>
        }
        <br/>
    </div>
  );
};

export default NewReleasePage;
