import React, { Children, useEffect, useState } from "react";
import AppLayout from "./components/layouts/AppLayout";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import firebase from "firebase";
import { useDispatch } from "react-redux";
import restClient, { RestService } from "./net/RestService";
import signIn from "./redux/actionCreators/signIn";
import QUERY_KEYS from "./net/query-keys";
import REQUESTS from "./net/requests";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

const App = ({ children }) => {
  const dispatch = useDispatch()

  const prefetchData = async () => {
    const staleTime = 1000 * 60 * 60;
    
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.RELEASE_TYPES], queryFn: REQUESTS.getReleaseTypes, staleTime })
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.GENRES], queryFn: REQUESTS.getGenres, staleTime })
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.CATEGORIES], queryFn: REQUESTS.getCategories, staleTime })
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.TAGS], queryFn: REQUESTS.getTags, staleTime })
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.STUDIOS], queryFn: REQUESTS.getStudios, staleTime })
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.RELEASE_STATUSES], queryFn: REQUESTS.getReleaseStatuses, staleTime })
    await queryClient.prefetchQuery({queryKey: [QUERY_KEYS.VOICE_TEAMS], queryFn: REQUESTS.getVoiceTeams, staleTime })
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      console.log('auth state changed', authUser)
      if (authUser) {
        const passProvider = authUser.providerData.find(
          (provider) => provider.providerId === "password"
        );
        const googleProvider = authUser.providerData.find(
          (provider) => provider.providerId === "google.com"
        );
        console.log('providers', passProvider, googleProvider)
        authUser.getIdTokenResult().then((idTokenResult) => {
          const currentProvider = idTokenResult.signInProvider;
          console.log('currentProvider', currentProvider)
          console.log('idTokenResult', idTokenResult.token)
          restClient.login(idTokenResult.token).then(serverResponse => {
            dispatch(signIn(serverResponse.data))
          })
        });
      }
    });

    prefetchData()
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppLayout>
          {children}
        </AppLayout>
      </QueryClientProvider>
    </>
  );
};

export default App;
