import './NewVideoPackButton.css'
import { CircularProgress, Grid, Box, Paper, TextField, Card, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, MenuItem, Typography } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import GetRequest from '../../../net/GetRequest';
import ENDPOINTS from '../../../net/endpoints';
import { useMutation, useQuery } from 'react-query';
import REQUESTS from '../../../net/requests';
import { useSelector } from 'react-redux';
import ROLES from '../../../constants/roles';
import { useRef, useState } from 'react';
import Players from '../../../constants/players';


const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.secondary,
  }));

const NewVideoPackButton = (props) => {
    const releaseId = props.releaseId
    const [open, setOpen] = useState(false)
    const [player, setPlayer] = useState(Players.SIBNET)
    const [episode, setEpisode] = useState()
    const [team, setTeam] = useState()
    const jsonRef = useRef()
    
    const teamsKey = `teams`
    const teamsRequest = useQuery(teamsKey, REQUESTS.getVoiceTeams)

    const mutation = useMutation(REQUESTS.postVideoPack)

    const buildJson = (text) => {
        if (!text || text.length === 0) {
            return []
        }
        if (text.substring(text.length - 1, text.length) === ",") {
            return JSON.parse("[" + text.substring(0, text.length - 1) + "]")
        } else {
            return JSON.parse("[" + text + "]")
        }
    }
    const handleClose = () => { setOpen(false) }
    const handleCreate = () => { 
        const videos = jsonRef.current ? buildJson(jsonRef.current.value) : undefined
        const data = {
            voiceTeamId: Number(team),
            releaseId: Number(releaseId),
            playerId: Number(player),
            videos
        }
        mutation.mutate(data, {
            onSuccess: (data, variables, context) => {
              handleClose()
            },
          })
    }

    if (teamsRequest.isLoading || teamsRequest.isError) {
        return <CircularProgress />
    }

    return <>
            <Button onClick={ () => setOpen(true) }>New Pack</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>New Pack</DialogTitle>
                <DialogContent>
                    <Title>Player:</Title>
                    <Select value={player} onChange={event => setPlayer(event.target.value)} >
                        { Object.keys(Players).map(player => <MenuItem key={Object.keys(Players).indexOf(player)} value={Object.values(Players)[Object.keys(Players).indexOf(player)]}>{player}</MenuItem> ) }
                    </Select>
                    <TextField
                        inputRef={jsonRef}
                        autoFocus
                        multiline
                        margin="dense"
                        id="url"
                        label="json"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <p>ReleaseId: {releaseId}</p>
                    <Title>Team:</Title>
                    <Select value={team} onChange={event => setTeam(event.target.value)} >
                        { teamsRequest.data.map(team => <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem> ) }
                    </Select>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
}

export default NewVideoPackButton;