import './PagedList.css'
import { CircularProgress, Grid, Box, Paper } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import GetRequest from '../../../net/GetRequest';
import ENDPOINTS from '../../../net/endpoints';
import ReleasePreview from '../ReleasePreview';
import { useHistory } from 'react-router';
import { Pages } from '../../../constants/urls';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import REQUESTS from '../../../net/requests';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const PagedList = (props) => {
    const history = useHistory();
    const queryClient = useQueryClient()
    const user = useSelector((state) => state?.user);

    const columns = 5;
    const pageSize = 15;
    const pageNumber = props.page;

    const releasesKey = 'releases'
    const request = useQuery(releasesKey, REQUESTS.getReleases)
    useEffect(() => {
        queryClient.invalidateQueries(releasesKey)
    }, [user])

    if (request.isLoading) {
        return <div id="center-content">
            <CircularProgress />
        </div>
    }
    if (request.isError) {
        return <div id="center-content">
            <p>Error!</p>
        </div>
    }
    const items = request.data
    return <div id='releases-grid'>
        {
            items.map(item => <ReleasePreview key={item.id} item={item} onClick={(release) => history.push(Pages.getReleasePage(release.id))}/>)
        }
    </div>
}

export default PagedList;