import './ReleasePreview.css'
import { CircularProgress, Grid, Box, Paper, TextField, Card, Typography } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import GetRequest from '../../../net/GetRequest';
import ENDPOINTS from '../../../net/endpoints';

const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.onBackground,
    fontSize: '1.2rem'
}));

const DarkText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.onSurface,
    fontSize: '1.2rem'
}));

const ReleasePreview = (props) => {
    const item = {
        titleRu: props.item.titleRu ? props.item.titleRu : props.item.titleEn,
        titleEn: props.item.titleEn,
        releaseSeasonDate: props.item.releaseSeasonDate,
        previewUrl: props.item.coverUrl ? props.item.coverUrl : "https://image-processor-storage.s3.us-west-2.amazonaws.com/images/3cf61c1011912a2173ea4dfa260f1108/halo-of-neon-ring-illuminated-in-the-stunning-landscape-of-yosemite.jpg",
        desc: props.item.descriptionRu ? props.item.descriptionRu : props.item.descriptionEn
    };

    const clickListener = () => {
        console.log(`on click: ${props.item}`)
        if (props.onClick) {
            props.onClick(props.item)
        }
    }
    
    return <div className='release-preview-container' onClick={clickListener}>
            <img className="release-preview-image" src={item.previewUrl} />
            <Text className='release-preview-title'>{item.titleRu}</Text>
            <div className='release-preview-details'>
                <DarkText className='release-preview-details-title'>{item.titleRu}</DarkText>
                <DarkText className='release-preview-details-desc'>{`${item.titleEn}, ${new Date(item.releaseSeasonDate).getFullYear()}`}</DarkText>
            </div>
        </div>
}

export default ReleasePreview;