import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import App from "./App";
import URLS from "./constants/urls";
import { Redirect } from "react-router";
import NotFoundPage from "./pages/NotFoundPage";
import {isUrlWrong} from "./utils/isUrlWrong";
import ReleasesPage from "./pages/ReleasesPage";
import ReleasePage from "./pages/ReleasePage";
import SearchPage from "./pages/SearchPage";
import MainPage from "./pages/MainPage";
import NewReleasePage from "./pages/NewReleasePage";

const Routers = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state?.user);

  const routes = [
    { path: URLS.RELEASE_NEW, component: NewReleasePage, exact: true },
    { path: URLS.RELEASE, component: ReleasePage, exact: true },
    { path: URLS.RELEASES, component: ReleasesPage, exact: true },
    { path: URLS.SEARCH_BY_TYPE, component: SearchPage, exact: true },
    { path: URLS.SEARCH, component: SearchPage, exact: true },
    { path: URLS.NOT_FOUND, component: NotFoundPage, exact: true },
    { path: URLS.ROOT_URL, component: MainPage, exact: false },
  ];

  const renderRouters = routes.map((route, idx) => (
    <Route
      exact={route.exact}
      path={route.path}
      component={route.component}
      key={route.path || idx}
    />
  ));

  return (
    <>
      {isUrlWrong(location) && <Redirect to={URLS.NOT_FOUND} push/>}
      <App>
        <Switch>{renderRouters}</Switch>
      </App>
      
    </>
  );
};

export default Routers;
