import React, { useState } from "react";

import { CircularProgress, styled, Typography } from "@material-ui/core";
import "./ReleasePage.css";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import REQUESTS from "../../net/requests";
import ReleaseEpisodes from "../../components/widgets/ReleaseEpisodes";
import { useSelector } from "react-redux";
import PlayerView from "../../components/widgets/PlayerView";
import ReleaseInfo from "../../components/widgets/ReleaseInfo";
import EpisodeInfo from "../../components/widgets/EpisodeInfo";
import QUERY_KEYS from "../../net/query-keys";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
}));

const ReleasePage = (props) => {
  const releaseId = props.match.params.releaseId
  const location = useLocation();
  const user = useSelector(state => state.user)
  const [currentEpisode, selectEpisode] = useState()
  
  const releaseKey = `release.${releaseId}`
  const releaseRequest = useQuery(releaseKey, REQUESTS.getRelease(releaseId))
  
  const episodesKey = `release.${releaseId}.episodes`
  const episodesRequest = useQuery(episodesKey, REQUESTS.getEpisodes(releaseId))
  
  const releaseTypesRequest = useQuery(QUERY_KEYS.RELEASE_TYPES, REQUESTS.getReleaseTypes)

  if (releaseRequest.isLoading || episodesRequest.isLoading || releaseTypesRequest.isLoading) {
    return <CircularProgress />
  }
  if (releaseRequest.isError || episodesRequest.isError || releaseTypesRequest.isError) {
    return <h2>Error</h2>
  }

  const release = releaseRequest.data
  const releaseType = releaseTypesRequest.data.find((v, i, a) => v.id === release.releaseTypeId)

  if (!currentEpisode) {
    const firstEpisode = episodesRequest.data && episodesRequest.data.length > 0 ? episodesRequest.data[0] : undefined
    console.log('first episode', firstEpisode)
    if (firstEpisode) {
      selectEpisode(firstEpisode)
    }
  }

  return (
    <div className="release-page">
      <div className="release-page-center">
        <div className="release-page-column">
          <Text variant="h4">{release.titleRu}</Text>
          <Text variant="h6">{`${release.titleEn}, ${new Date(release.releaseSeasonDate).getFullYear()}, ${releaseType.name}`}</Text>
          <div className="release-page-content">
            <div className="release-page-images">
              <img src={release.coverUrl} />
            </div>
            <div className="release-page-info">
              <ReleaseInfo release={release} />
            </div>
          </div>
          <div className="release-page-margin-16">
            <Text variant="h6">Сюжет аниме</Text>
            <Text className="release-page-desc" style={{ marginTop: 8 }}>{release.descriptionRu}</Text>
          </div>
          <ReleaseEpisodes releaseId={releaseId} currentItem={episodesRequest.data.indexOf(currentEpisode) + 1} onItemSelected={item => selectEpisode(item)}/>

          { currentEpisode ? <PlayerView releaseId={releaseId} episodeId={currentEpisode.id} /> : <CircularProgress /> }

          <EpisodeInfo current={currentEpisode} episodes={episodesRequest.data} onClick={index => selectEpisode(episodesRequest.data[index])} />
        </div>
      </div>
    </div>
  );
};

export default ReleasePage;
