import { CircularProgress, styled, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Players from "../../../constants/players";
import "./MainPageList.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useQuery } from "react-query";
import REQUESTS from "../../../net/requests";
import ReleasePreview from "../ReleasePreview";
import { useHistory } from "react-router";
import { Pages } from "../../../constants/urls";


const MainPageList = (props) => {
//Expanding Collection + Carousel Slider

  const history = useHistory();

  const items = props.items
  const slidesPerView = props.slidesPerView
  console.log(`slidesPerView: ${slidesPerView}, items count: ${items.length}`)
  return (
    <Swiper
      spaceBetween={32}
      slidesPerView={slidesPerView}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {
          items.map(item => <SwiperSlide key={item.id}><ReleasePreview item={item} onClick={(release) => history.push(Pages.getReleasePage(release.id))}/></SwiperSlide>)
      }
    </Swiper>
  );
};

export default MainPageList;