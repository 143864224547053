import React, { useState } from "react";
import "./AltNameSelector.css";
import { Button, Chip, styled, TextField, Typography } from '@material-ui/core';

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const AltNameSelector = (props) => {
  console.log(`AltNameSelector:`, props.altName)

  const altName = props.altName
  const id = altName.id
  const onItemsChanged = props.onItemChanged
  const onItemDeleted = props.onItemDeleted

  const [item, setItem] = useState(altName)

  const updateItem = (lang, name) => {
    const newItem = { id, lang, name }
    console.log(`AltNameSelector newItem: `, props.altName)
    setItem(newItem)
    if (onItemsChanged) onItemsChanged(newItem)
  }
  console.log(`AltNameSelector item:`, item)

  return (
    <>
      <div>
        <TextField className="alt-lang" label="Lang" variant="outlined" size="small" value={item.lang} onChange={event => updateItem(event.target.value, item.name)} style={{ width: 100 }}/>
        <TextField className="alt-value" label="Alt Name" variant="outlined" size="small" value={item.name} onChange={event => updateItem(item.lang, event.target.value)} style={{ width: 600 }}/>
        
        <Button variant="outlined" onClick={() => { onItemDeleted(item) }} style={{ width: 200 }}>
          Remove
        </Button>
      </div>
      <br/>
    </>
  );
};

export default AltNameSelector;