import React, { useRef } from "react";
import "./NewCategory.css";
import { Button, styled, TextField, Typography } from '@material-ui/core';
import { useMutation, useQueryClient } from "react-query";

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const NewCategory = (props) => {
  const hint = props.hint
  const buttonText = props.buttonText
  const onNameAdded = props.onNameAdded
  const request = props.request

  const newItemRef = useRef()
  const mutation = useMutation(request)

  const addItem = () => {
    const name = newItemRef.current.value
    
    if (name) {
      mutation.mutate(name, {
        onSuccess: (data, variables, context) => {
          if (onNameAdded) {
            onNameAdded(data)
          }
        },
      })
    }
    newItemRef.current.value = ""
  }

  if (mutation.isError) {
    setTimeout(() => {
      mutation.reset()
    }, 3000)
  }

  return (
    mutation.isLoading ? <p>Loading</p> :
      mutation.isError ? <p>Error</p> :
        <>
          <TextField inputRef={newItemRef} id="newItem" label={hint} variant="outlined" size="small" />
          <Button variant="outlined" onClick={addItem} style={{ width: 200, marginLeft: 16 }}>
            {buttonText}
          </Button>
        </>
  );
};

export default NewCategory;