import React, { useRef, useState } from "react";
import "./AltNamesSelector.css";
import { Button, Chip, styled, TextField, Typography } from '@material-ui/core';
import AltNameSelector from "../AltNameSelector";

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const AltNamesSelector = (props) => {
  console.log(`${props.title}:`, props.items)

  const title = props.title
  const all = props.items
  const onItemsChanged = props.onItemsChanged

  const newItemRef = useRef()
  const [items, setItems] = useState([])

  const updateItems = (items) => {
    setItems(items)
    if (onItemsChanged) onItemsChanged(items)
  }
  const withoutItem = (id) => items.filter((item) => item.id !== id)

  console.log(items);
  return (
    <>
      <Title>Alt Names</Title>
      <div className="alt-names">
        {
          items.map(item => <AltNameSelector key={item.id} altName={item} onItemChanged={altName => updateItems(items.map(item => item.id === altName.id ? altName : item))} onItemDeleted={(item) => updateItems(withoutItem(item.id))}/>)
        }
        <Button variant="outlined" onClick={() => { updateItems([...items, { id: items.length > 0 ? items[items.length - 1].id + 1 : 0, lang: "", name: "" }]) }} style={{ width: 200 }}>
          Add one more
        </Button>
      </div>
    </>
  );
};

export default AltNamesSelector;