import ACTION_LOG_OUT from "../actions/logout";
import ACTION_SIGN_IN from "../actions/signIn";
import ACTION_UPDATE_USER from "../actions/updateUser";

export default function user(state = null, action) {
  switch (action.type) {
    case ACTION_SIGN_IN: {
      return action.user;
    }
    case ACTION_UPDATE_USER: {
      return action.user;
    }
    case ACTION_LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
