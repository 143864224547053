import * as React from 'react';
import "./AccountInfo.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Popper, Grow, Paper, MenuItem, ClickAwayListener, MenuList } from "@material-ui/core";
import firebase from "firebase";
import restService from "../../../net/RestService"
import signIn from "../../../redux/actionCreators/signIn";
import logout from "../../../redux/actionCreators/logout";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
const googleProvider = new firebase.auth.GoogleAuthProvider();

const AccountInfo = ({ children }) => {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleGoogleLogin = async (event) => {
    const firebaseResponse = await firebase.auth().signInWithPopup(googleProvider);
    const token = await firebaseResponse.user.getIdToken();
    const serverResponse = await restService.login(token)
    dispatch(signIn(serverResponse.data));
    handleClose(event);
  };

  const handleLogout = async (event) => {
    await firebase.auth().signOut()
    restService.setToken(null)
    dispatch(logout());
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const user = useSelector((state) => state?.user);

  return (
    <>
      <div id="avatar-no-photo">
        <IconButton 
          ref={anchorRef}
          aria-label="avatar" 
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle} 
        >
          <img src={user ? user.avatar : "/img/no_photo.png"} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                      user ? 
                      <MenuItem onClick={handleLogout}>Log out</MenuItem> : 
                      <MenuItem onClick={handleGoogleLogin}>Sign in with Google</MenuItem>
                    }
                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

export default AccountInfo;
