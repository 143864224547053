import React from "react";

import AppLayout from "../../components/layouts/AppLayout";
import ENDPOINTS from "../../net/endpoints";
import GetRequest from "../../net/GetRequest";
import { CircularProgress } from "@material-ui/core";
import "./ReleasesPage.css";
import PagedList from "../../components/widgets/PagedList";
import { useSelector } from "react-redux";

const ReleasesPage = (props) => {
  const user = useSelector((state) => state?.user);
  console.log('user:', user)
  return (
    <div className="releases-page">
       <PagedList page="0" />
    </div>
  );
};

export default ReleasesPage;
