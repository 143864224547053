const RELEASES_PATH = '/releases'

const URLS = {
  ROOT_URL: "/",
  NOT_FOUND: "/404",
  RELEASE: `${RELEASES_PATH}/:releaseId`,
  RELEASES: RELEASES_PATH,
  RELEASE_NEW: `${RELEASES_PATH}/new`,
  SEARCH_BY_TYPE: "/search/:type/:id",
  SEARCH: "/search",
};

export const Pages = {
  getReleasePage: (id) => `${RELEASES_PATH}/${id}`
}

export default URLS;
