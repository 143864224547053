import { styled, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Players from "../../../constants/players";
import "./PlayerSelector.css";

const TeamName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer'
}));

const ActiveTeamName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  cursor: 'pointer'
}));

const PlayerSelector = (props) => {
  const players = props.items
  const current = props.current
  const onChange = props.onChange

  const onItemClicked = (item) => {
    if (item !== current) {
      onChange(item)
    }
  }

  const viewForPlayer = (id) => {
    const name =  Object.keys(Players)[Object.values(Players).indexOf(id)]
    const onClickListener = () => onItemClicked(id)
    if (current === id) {
      return <ActiveTeamName key={id} onClick={onClickListener}>{name}</ActiveTeamName>
    } else {
      return <TeamName key={id} onClick={onClickListener}>{name}</TeamName>
    }
  }

  
  return (
    <ul className="player-selector-list">
      {players.map(player => viewForPlayer(player))}
    </ul>
  );
};

export default PlayerSelector;