import './ReleaseInfo.css'
import { CircularProgress, Grid, Box, Paper, TextField, Card, Typography, Chip, Button } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import GetRequest from '../../../net/GetRequest';
import ENDPOINTS from '../../../net/endpoints';
import REQUESTS from '../../../net/requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SearchTypes from '../../../constants/search-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import ROLES from '../../../constants/roles';
import Activities, { ServerActivities } from '../../../constants/activities';
import QUERY_KEYS from '../../../net/query-keys';


const ChipLink = styled(Chip)(({ theme }) => ({
    marginLeft: 4,
    marginRight: 4,
    marginTop: 2,
    marginBottom: 2,
    '&:first-of-type': {
        marginLeft: 0
    }
}));

const TextKey = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.onBackground,
    fontSize: '1.25rem'
}));

const TextValue = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.onBackground,
    fontSize: '1rem'
}));

const noValue = <TextValue>-</TextValue>

const findStatusName = (request, statusId) => {
    if (request.isLoading) {
        return 'Loading'
    } 
    if (request.isError) {
        return 'Error'
    } 
    const releaseStatus = request.data.find(v => v.id === statusId)
    return releaseStatus ? releaseStatus.name : '-'
}

const findTypeName = (request, releaseTypeId) => {
    if (request.isLoading) {
        return 'Loading'
    } 
    if (request.isError) {
        return 'Error'
    } 
    const releaseType = request.data.find(v => v.id === releaseTypeId)
    return releaseType ? releaseType.name : '-'
}

const findGenres = (request, genresIds, history) => requestLabelOr(request, () => {
    const genres = request.data.filter(v => genresIds.indexOf(v.id) !== -1)
    return genres && genres.length > 0 ? genres.map(genre => <ChipLink key={genre.id} label={genre.name} variant="outlined" onClick={() => history.push(`/search/${SearchTypes.GENRES}/${genre.id}`)} />) : noValue
})

const findCategories = (request, categoriesIds, history) => requestLabelOr(request, () => {
    const categories = request.data.filter(v => categoriesIds.indexOf(v.id) !== -1)
    return categories && categories.length > 0 ? categories.map(category => <ChipLink className="qwer" key={category.id} label={category.ruName} variant="outlined" onClick={() => history.push(`/search/${SearchTypes.CATEGORIES}/${category.id}`)} />) : noValue
})

const findTags = (request, tagIds, history) => requestLabelOr(request, () => {
    const tags = request.data.filter(v => tagIds.indexOf(v.id) !== -1)
    return tags && tags.length > 0 ? tags.map(tag => <ChipLink key={tag.id} label={tag.name} variant="outlined" onClick={() => history.push(`/search/${SearchTypes.TAGS}/${tag.id}`)} />) : noValue
})

const findStudios = (request, studioId, history) => requestLabelOr(request, () => {
    const studios = request.data.filter(v => studioId === v.id)
    const studio = studios && studios.length > 0 ? studios[0] : undefined
    return studio ? <ChipLink label={studio.name} variant="outlined" onClick={() => history.push(`/search/${SearchTypes.STUDIOS}/${studio.id}`)} /> : noValue
})


const requestLabelOr = (request, callback) => {
    if (request.isLoading) {
        return <TextValue>'Loading'</TextValue>
    } 
    if (request.isError) {
        return <TextValue>'Error'</TextValue>
    } 
    return callback()
}

const ReleaseInfo = (props) => {
    const release = props.release

    const history = useHistory()
    const user = useSelector(state => state.user)

    const queryClient = useQueryClient()
    const activityMutation = useMutation(REQUESTS.postReleaseActivity(release.id))

    const releaseSeasonDate = new Date(release.releaseSeasonDate)
    
    const statusesRequest = useQuery(QUERY_KEYS.RELEASE_STATUSES, REQUESTS.getReleaseStatuses)
    const typesRequest = useQuery(QUERY_KEYS.RELEASE_TYPES, REQUESTS.getReleaseTypes)
    const genresRequest = useQuery(QUERY_KEYS.GENRES, REQUESTS.getGenres)
    const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)
    const tagsRequest = useQuery(QUERY_KEYS.TAGS, REQUESTS.getTags)
    const studiosRequest = useQuery(QUERY_KEYS.STUDIOS, REQUESTS.getStudios)

    const updateActivity = (activityValue) => {
        activityMutation.mutate(activityValue, {
            onSuccess: (data, error, variables, context) => {
              console.log('activityValue updated!')
              queryClient.invalidateQueries(`release.${release.id}`, { exact: true })
            },
          })
    }

    const adminTools = <div className='release-info-table-row'>
        <div className='release-info-table-row-left'><TextKey>Release State:</TextKey></div>
        <div className='release-info-table-row-right'>
            <TextValue>{Activities[release.activity]}</TextValue>
            <Button style={{ marginLeft: 16 }} variant="outlined" onClick={() => updateActivity(ServerActivities.DRAFT)} >To Draft</Button>
            <Button variant="outlined" onClick={() => updateActivity(ServerActivities.WIP)} >To In progress</Button>
            <Button variant="outlined" onClick={() => updateActivity(ServerActivities.COMPLETED)} >To Complete</Button>
        </div>
    </div>

    return <div className='release-info-table'>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Тип:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{findTypeName(typesRequest, release.releaseTypeId)}</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Рейтинг:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{release.rating}</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Статус:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{findStatusName(statusesRequest, release.statusId)}</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Сезон:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{release.seasonNumber}</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Год:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{new Date(release.releasedDate).getFullYear().toString()}</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Эпизоды:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{release.episodesAired} из {release.episodesCount}, ~{release.episodeDuration} мин.</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Сезон:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{`${releaseSeasonDate.getMonth() + 1}/${releaseSeasonDate.getFullYear()}`}</TextValue></div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Студия:</TextKey></div>
            <div className='release-info-table-row-right'>{findStudios(studiosRequest, release.studioId, history)}</div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Жанры:</TextKey></div>
            <div className='release-info-table-row-right'>{findGenres(genresRequest, release.genreIds, history)}</div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Категории:</TextKey></div>
            <div className='release-info-table-row-right'>{findCategories(categoriesRequest, release.categoryIds, history)}</div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Тэги:</TextKey></div>
            <div className='release-info-table-row-right'>{findTags(tagsRequest, release.tagIds, history)}</div>
        </div>
        <div className='release-info-table-row'>
            <div className='release-info-table-row-left'><TextKey>Просмотры:</TextKey></div>
            <div className='release-info-table-row-right'><TextValue>{release.views}</TextValue></div>
        </div>
        { user && (user.role === ROLES.MODERATOR || user.role === ROLES.ADMIN) && adminTools }
    </div>
}

export default ReleaseInfo;