import React from "react";
import "./Header.css";
import { Button, TextField } from '@material-ui/core';
import AccountInfo from "../AccountInfo";
import GoogleButton from "../../GoogleButton";
import firebase from "firebase/app";
import URLS from "../../../constants/urls";
import { useHistory } from "react-router";
import LiveSearch from "../LiveSearch";
import ROLES from "../../../constants/roles";
import { useSelector } from "react-redux";

const Header = ({ children }) => {
  const history = useHistory();
  const user = useSelector(state => state.user)

  const createButton = <Button variant="outlined" onClick={() => history.push(URLS.RELEASE_NEW)}>Create</Button>

  return (
    <>
      <div id="header">
        <LiveSearch />
        
        { user && (user.role === ROLES.MODERATOR || user.role === ROLES.ADMIN) && createButton }

        <AccountInfo id="account-info"/>
      </div>
    </>
  );
};

export default Header;