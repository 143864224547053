import { styled, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import "./TeamSelector.css";

const TeamName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer'
}));

const ActiveTeamName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  cursor: 'pointer'
}));

const TeamSelector = (props) => {
  const teams = props.teams
  const currentTeam = props.current
  const onChange = props.onChange

  const teamsReuqest = useQuery(QUERY_KEYS.VOICE_TEAMS, REQUESTS.getVoiceTeams)
  const notLoaded = teamsReuqest.isLoading || teamsReuqest.isError

  const onItemClicked = (item) => {
    if (item !== currentTeam) {
      onChange(item)
    }
  }

  const viewForTeam = (id) => {
    const name = notLoaded ? `${id}` : teamsReuqest.data.find(v => v.id === id).name
    const onClickListener = () => onItemClicked(id)
    if (currentTeam === id) {
      return <ActiveTeamName key={id} onClick={onClickListener} >{name}</ActiveTeamName>
    } else {
      return <TeamName key={id} onClick={onClickListener} >{name}</TeamName>
    }
  }

  return (
    <ul className="team-selector-list">
      {teams.map(team => viewForTeam(team))}
    </ul>
  );
};

export default TeamSelector;