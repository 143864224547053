
const QUERY_KEYS = {
    RELEASE_TYPES: `releaseTypes`,
    GENRES: `genres`,
    CATEGORIES: `categories`,
    TAGS: `tags`,
    STUDIOS: `studios`,
    RELEASE_STATUSES: `statuses`,
    VOICE_TEAMS: `teams`,
  }
  
  export default QUERY_KEYS;