import React from "react";
import axios from "axios";

const defaultState = {
  responseData: null,
  isFetching: true,
  error: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "fetched":
      return {
        ...state,
        isFetching: false,
        responseData: action.payload,
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

class GetRequest extends React.Component {
  state = defaultState;
  axiosSource = null;

  tryToCancel() {
    if (this.axiosSource) {
      this.axiosSource.cancel();
    }
  }

  dispatch(action) {
    this.setState((prevState) => reducer(prevState, action));
  }

  fetch = () => {
    this.tryToCancel();
    this.axiosSource = axios.CancelToken.source();
    const headers = this.props.token ? { authtoken: this.props.token } : {};
    axios
      .get(this.props.url, {
        cancelToken: this.axiosSource.token,
        headers
      })
      .then((response) => {
        this.dispatch({ type: "fetched", payload: response.data });
      })
      .catch((error) => {
        this.dispatch({ type: "error", payload: error });
      });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.url !== this.props.url ||
      prevProps.lastUpdate !== this.props.lastUpdate
    ) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.tryToCancel();
  }

  render() {
    return this.props.children(this.state);
  }
}

export default GetRequest;
