import axios from "axios";
import ENDPOINTS from "./endpoints";
import firebase from "firebase";


const client = axios.create();

export class RestService {
  token;

  async setToken(token) {
    this.token = token;
  }

  // user

  async login(idToken) {
    await this.setToken(idToken);
    let body = {};
    const response = await this.post(ENDPOINTS.LOGIN, body);
    return response;
  }

  // axios

  async post(url, body) {
    try {
      return await client.post(url, body, {
        headers: this.buildHeaders(),
      });
    } catch (error) {
      return await this.refreshToken(
        error,
        async () =>
          await client.post(url, body, {
            headers: this.buildHeaders(),
          })
      );
    }
  }

  async multipartPost(url, body) {
    try {
      return await client.post(url, body, {
        headers: this.buildMultipartHeaders(),
      });
    } catch (error) {
      return await this.refreshToken(
        error,
        async () =>
          await client.post(url, body, {
            headers: this.buildMultipartHeaders(),
          })
      );
    }
  }

  async put(url, body) {
    try {
      return await client.put(url, body, {
        headers: this.buildHeaders(),
      });
    } catch (error) {
      return await this.refreshToken(
        error,
        async () =>
          await client.put(url, body, {
            headers: this.buildHeaders(),
          })
      );
    }
  }

  async get(url, props) {
    try {
      return await client.get(url, {
        headers: this.buildHeaders(),
        ...props,
      });
    } catch (error) {
      return await this.refreshToken(
        error,
        async () =>
          await client.get(url, {
            headers: this.buildHeaders(),
            ...props,
          })
      );
    }
  }

  async delete(url) {
    try {
      return await client.delete(url, {
        headers: this.buildHeaders(),
      });
    } catch (error) {
      return await this.refreshToken(
        error,
        async () =>
          await client.delete(url, {
            headers: this.buildHeaders(),
          })
      );
    }
  }

  async patch(url, body) {
    try {
      return await client.patch(url, body, {
        headers: this.buildHeaders(),
      });
    } catch (error) {
      return await this.refreshToken(
        error,
        async () =>
          await client.patch(url, body, {
            headers: this.buildHeaders(),
          })
      );
    }
  }

  buildHeaders() {
    const headers = {
      "Content-Type": "application/json"
    };
    if (this.token) {
      headers.authtoken = this.token;
    }
    //console.log("with headers:", headers)
    return headers;
  }

  buildMultipartHeaders() {
    const headers = {
      "Content-Type": "multipart/form-data",
      authtoken: this.token,
    };
    return headers;
  }

  async refreshToken(err, successCallback) {
    const originalConfig = err.config;
    if (err.response && err.response.status === 401 && !originalConfig._retry) {
      // Access Token was expired
      originalConfig._retry = true;
      try {
        await firebase.auth().onIdTokenChanged(async (fireBaseUser) => {
          if (fireBaseUser) {
            const token = await fireBaseUser.getIdToken();
            await this.setToken(token);
            successCallback();
          }
        });
      } catch (e) {
        // throw e;
      }
      throw err;
    } else {
      throw err;
    }
  }
}

const restClient = new RestService();
export default restClient 