import React from "react";
import "./NotFoundPage.css"

import AppLayout from "../../components/layouts/AppLayout";

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <p>404</p>
      <p>NOT FOUND</p>
    </div>
  );
};

export default NotFoundPage;
