import React, { useEffect, useRef, useState } from "react";
import "./ReleaseTypeSelector.css";
import { Button, Chip, MenuItem, Select, styled, TextField, Typography } from '@material-ui/core';

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
}));

const ReleaseTypeSelector = (props) => {

  const title = props.title
  const items = props.items
  const onItemSelected = props.onItemSelected

  const [selected, setSelected] = useState(items && items.length > 0 ? items[0].id : undefined)

  const setSelectedWithNotification = (item) => {
    setSelected(item)
    if (onItemSelected) onItemSelected(item)
  }

  return (
    <>
      <Title>{title}</Title>
      <Select
        labelId="selectionLabel"
        id="selectionId"
        value={selected}
        label="Select one"
        onChange={(event) => setSelectedWithNotification(event.target.value)}
      >
        {items.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem> )}
      </Select>
    </>
  );
};

export default ReleaseTypeSelector;