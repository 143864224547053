import { styled, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import PopularReleases from "../../components/widgets/PopularReleases";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import "./MainPage.css"

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));

const RESET_TIMEOUT = 150;
const ITEM_WIDTH = 312;

const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};

const MainPage = () => {
  const targetRef = useRef();
  const timer = useRef(null);
  // const dim = useContainerDimensions(targetRef)
  // console.log(`dim: `, dim)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const genresRequest = useQuery(QUERY_KEYS.GENRES, REQUESTS.getGenres);
  
  const testDimensions = () => {
    console.log(`testDimensions: ${targetRef.current}`)
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
      console.log(`setDimensions, w: ${targetRef.current.offsetWidth}`)
    }
  }

  const nextTick = () => {
    console.log(`nextTick`)
    if (targetRef.current) {
      console.log(`call testDimensions`)
      testDimensions()
    } else {
      setTimeout(() => nextTick(), RESET_TIMEOUT)
    }
  }

  useLayoutEffect(() => {
    console.log(`useLayoutEffect`)
    if (targetRef.current) {
      testDimensions()
    } else {
      nextTick()
    }
  }, []);

  window.addEventListener('resize', () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    console.log(`setTimeout, w: ${dimensions.width}`)
    timer.current = setTimeout(testDimensions, RESET_TIMEOUT);
  });

  if (genresRequest.isLoading) {
      return <div className="main-page"><Text>'Loading'</Text></div>
  } 
  if (genresRequest.isError) {
      return <div className="main-page"><Text>'Error'</Text></div>
  } 
  const genres = genresRequest.data
  const intRandom = (max) => Math.floor(Math.random() * max)
  const genresIds = [intRandom(genres.length), intRandom(genres.length), intRandom(genres.length)].filter((v, i, a) => a.indexOf(v) === i)
  console.log(`genresIds: ${genresIds}, genres: ${genres.map(g => g.id)}`)

  const slidesPerView = Math.trunc(dimensions.width / ITEM_WIDTH);

  return (
    <div className="main-page" ref={targetRef}>
      <Text className="site-in-progress" align="center">Сайт сейчас находится в разработке, не обращайте внимание на мелкие недочеты - скоро все исправим 😄</Text>
      <Text className="site-in-progress">Popular</Text>
      <PopularReleases requestKey="releases.popular" requestItem={REQUESTS.getPopularReleases} slidesPerView={slidesPerView} />
      <Text className="site-in-progress">Last</Text>
      <PopularReleases requestKey="releases.latest" requestItem={REQUESTS.getLatestReleases} slidesPerView={slidesPerView} />
      {
        genres.length > 0 && genresIds.map(index => {
          const genre = genres[index]
          return <div key={`genre.${genre.id}`}>
            <Text className="site-in-progress">{genre.name}</Text>
            <PopularReleases requestKey={`releases.genres.${[genre.id]}`} requestItem={REQUESTS.getByGenres([genre.id])} slidesPerView={slidesPerView} />
          </div>
        })
      }
    </div>
  );
};

export default MainPage;
