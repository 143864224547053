import React, { useEffect, useRef, useState } from "react";
import "./AniboomPlayerView.css";

const AniboomPlayerView = (props) => {

  const url = props.url

  return (
    <>
      <iframe width="987" height="555" src={url} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" scrolling="no" allowFullScreen={true} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </>
  );
};

export default AniboomPlayerView;